import React from "react"
import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"

import coverImg from "assets/img/mammogram.jpg"

const MammogramPage = () => {
  return (
    <Layout>
      <section
        className="sc-mammogram-landing py-main"
        style={{ backgroundImage: `url(${coverImg})` }}
      >
        <div className="container">
          <div className="row row-header mb-5">
            <div className="col-lg-4 pr-0">
              <div className="mammogram-header mb-3">
                <h2 className="title-1 mb-0">Beli 1</h2>
                <h2 className="mb-0">Paket mammogram</h2>
              </div>
            </div>
            <div className="col-lg-4 col-right">
              <div className="mammogram-header-2">
                <h2 className="title-2">Donasi 1</h2>
                <p>
                  <b>GRATIS</b> untuk mereka yang kurang beruntung
                </p>
              </div>
            </div>
          </div>
          <div className="row row-body">
            <div className="col-lg-4 col-left">
              <p className="">
                Dengan membeli 1 paket Mammogram, otomatis 1 paket Mammogram
                didonasikan untuk mereka yang kurang beruntung.
              </p>
            </div>
            <div className="col-lg-4">
              <h6>Apa yang kamu dapatkan?</h6>
              <ul>
                <li>Pemeriksaan Mamogram</li>
                <li>Buku Panduan #SaveIbuku</li>
                <li>Layanan Aman Personal Concierge</li>
                <li>Aman Voucher senilai Rp 100.000-</li>
                <li>Konsultasi dengan Dokter dan Psikolog terpilih*</li>
              </ul>
              <small>*Jika hasil pemeriksaanmu positif</small>
            </div>
          </div>
          <div className="position-relative">
            <h2 className="price-tag">Rp 525.000</h2>
          </div>
          <Button
            variant="primary"
            //  link="/mammogram/form"
            link="https://campaign.dompetaman.com/en/health/bookingservice"
          >
            <i className="air ai-shopping-cart mr-3" />
            Pesan Sekarang
          </Button>
        </div>
      </section>
    </Layout>
  )
}

export default MammogramPage
